import ApiService from '@/services/core/api.service'

const SDisbursement = {

  async getReports (query = '') {
    return ApiService.get(`merchant/reports/disbursements?${query}`)
  },

  async getExports (query = '') {
    return ApiService.get(`merchant/exports/disbursements?${query}`)
  }

}

export default SDisbursement
