function view (path) {
  return () => import('@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RAdmin = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    authorizedRole: 'admin'
  },
  {
    path: '/admin/disbursements',
    component: view('Disbursements'),
    name: 'admin.disbursements',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Disbursement',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/accounts/users',
    component: view('UserAccounts'),
    name: 'admin.accounts.users',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Accounts'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/accounts/merchants',
    component: view('merchants/Base'),
    name: 'admin.accounts.merchants',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Accounts'
        },
        {
          text: 'Merchants',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/services/banks',
    component: view('Banks'),
    name: 'admin.services.banks',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Services'
        },
        {
          text: 'Banks',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/webhooks/calls',
    component: view('webhooks/Calls'),
    name: 'admin.webhooks.calls',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Webhooks'
        },
        {
          text: 'Calls',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/webhooks/events',
    component: view('webhooks/Events'),
    name: 'admin.webhooks.events',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Webhooks'
        },
        {
          text: 'Events',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/webhooks/logs',
    component: view('webhooks/Logs'),
    name: 'admin.webhooks.logs',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Webhooks'
        },
        {
          text: 'Logs',
          active: true
        }
      ]
    }
  }
]

export default RAdmin
