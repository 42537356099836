import axios, { AxiosError } from 'axios'
import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'

// Request interceptor
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["config"] }] */
axios.interceptors.request.use(config => {
  const token = store.getters['auth/token']
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return config
})

// Response interceptor
axios.interceptors.response.use(response => Promise.resolve(response), async error => {
  let message = {
    title: 'Error Connection',
    text: 'Please check your internet connection'
  }

  let result = { // throw original result
    message: error?.message || ''
  }

  let code = 500

  if ((error.response && error.response.data)) {
    const { data, status } = error.response // , statusText

    result = data
    code = status

    switch (status) {
      case 400: { // bad request
        message = {
          title: 'Oops!', // 'Invalid Credentials / Payload',
          text: data.message
        }
        break
      }
      case 401: {
        message = {
          title: 'Session Expired', // 'Session Expired',
          text: 'Due to inactivity, please log in again'
        }
        break
      }
      case 403: {
        message = {
          title: 'Forbidden Page', // 'Unauthorize Access',
          text: 'You are attempting to access a restricted page.'
        }
        break
      }
      case 404: {
        message = {
          title: 'Page Not Found',
          text: 'You are attempting to access a unknown page.'
        }
        break
      }
      case 413: {
        message = {
          title: 'Attachment Too Large',
          text: 'Please limit the size of your files.'
        }
        break
      }
      case 422: { // unprocessable
        message = {
          title: 'Validation Error', // 'Invalid Data Format',
          text: 'Your inputs seem to be incorrect or incomplete, please check and try again.'
        }
        // return Promise.reject(new AxiosError(result, code))
        break
      }
      case 429: { // many request
        message = {
          title: 'Too Many Request',
          text: 'Please limit reloading the page'
        }
        break
      }
      default: {
        message = {
          title: 'Oops!', // 'Server Error'
          text: 'Something went wrong, please contact your System Administrator'
        }
      }
    }
  } else {
    if (navigator.onLine) {
      message = {
        title: 'Oops!', // 'Server Error'
        text: 'Something went wrong, please contact your System Administrator'
      }
    }
  }

  return Swal.fire({
    title: message.title,
    html: `<h6>${message.text}</h6>`,
    allowEscapeKey: false,
    allowOutsideClick: false,
    focusConfirm: true,
    showConfirmButton: true,
    confirmButtonText: 'Dismiss',
    confirmButtonColor: '#FF2929',
    backdrop: true,
    customClass: {
      title: 'h4 p-2 text-danger border-bottom',
      htmlContainer: 'p-2 m-0',
      actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0',
      cancelButton: 'btn btn-sm btn-danger',
      confirmButton: 'btn btn-sm btn-success'
    },
    width: '25rem'
  }).then(() => {
    if ([401, 403].includes(code)) {
      if (store.getters['auth/check']) {
        store.dispatch('auth/logout')
      }

      router.replace({ name: 'shared.login' })
    }
    return Promise.reject(new AxiosError(result, code))
  })
})
