function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/merchant/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RUser = [
  {
    path: '/merchant/dashboard',
    component: view('Dashboard'),
    name: 'merchant.dashboard',
    authorizedRole: 'merchant'
  },
  {
    path: '/merchant/reports/disbursements',
    component: view('Disbursements'),
    name: 'merchant.reports.disbursements',
    authorizedRole: 'merchant',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'Disbursement',
          active: true
        }
      ]
    }
  },
  {
    path: '/merchant/reports/replenishments',
    component: view('Replenishments'),
    name: 'merchant.reports.replenishments',
    authorizedRole: 'merchant',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'Replenishments',
          active: true
        }
      ]
    }
  }
]

export default RUser
