import ApiService from '@/services/core/api.service'

const SReplenishment = {

  async getReports (query = '') {
    return ApiService.get(`merchant/reports/replenishments?${query}`)
  },

  async getExports (query = '') {
    return ApiService.get(`merchant/exports/replenishments?${query}`)
  }

}

export default SReplenishment
