import ApiService from '@/services/core/api.service'

const SDisbursement = {

  async getReports (query = '') {
    return ApiService.get(`admin/disbursements?${query}`)
  }

}

export default SDisbursement
